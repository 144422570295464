.groups-overview-icon {
  width: 2rem;
  height: 2rem;
}

.groups-overview-title {
  font-size: 18px;
  font-weight: bold;
}

.groups-overview-btns {
  margin-left: auto;
}
