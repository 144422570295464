.my-paths-search {
  margin-right: auto;
}


.my-paths-status-select {
  // height: 37px;
  
  &-input {
    padding: 0;
    height: 37px;
  }
}

.my-paths-list {
  overflow: visible;
}