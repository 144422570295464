@import '../../../../../../../../common/variables.scss';
@import '../../../../../../../../common/helper.scss';

.browse-file-option-container,
.upload-file-btn-container {
  text-align: center;
}

.upload-file-btn-container {
  margin: em(12);
}

.import-csv-headline h5 {
  font-size: em(18);
  font-family: OpenSans;
}

.cancel-symbol {
  position: absolute;
  top: em(10);
  right: em(10);
  cursor: pointer;
  font-size: em(26);
  width: em(15);
  height: em(15);
  text-align: center;
  line-height: em(15);
}

.cancel-symbol:hover {
  background-color: $color-WhiteMetalLighter;
  border-radius: em(50);
}

.user-import-loader {
  margin-top: em(-40);
  margin-bottom: em(-66.5);
}
