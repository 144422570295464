@import '../../../common/variables.scss';
@import '../../../common/helper.scss';

.comment-content {
  padding-left: 0.4rem;
}

.comment-user-name {
  font-size: 0.75rem;
  font-weight: bold;
  margin-left: 0.2rem !important;
}

.comment {
  font-size: 0.75rem;
  min-width: 100%;
  margin-left: em(-20);
}
