@import '../../../common//variables.scss';

.form-checkbox {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 0.5rem;
  border: 1px dashed transparent;

  &-error {
    @extend .form-checkbox;
    border-color: $color-error;
  }

  &-checkbox {
    margin-top: 0.25rem;
  }

  &-text {
    font-family: OpenSans;
    font-size: 12px;
    color: $color-text;
    margin: 0;

    &-required {
      color: $color-error;
    }
  }
}
