.confirm-action {
  max-width: 450px;
  width: 100%;

  &-title {
    font-weight: bold;
    font-size: 1rem;
    padding: 1rem 2rem;
  }

  &-text {
    font-size: 14px;
    padding: 1rem 2rem;
  }

  &-btns {
    flex-direction: row;
    flex-wrap: wrap;
    gap: 0.75rem;
    justify-content: flex-end;
    padding: 1.5rem 2rem;
  }
}
