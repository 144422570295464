@import '../../common/variables.scss';
@import '../../common/helper.scss';

.external-login-form {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;

  .error-message {
    font-size: 14px;
    color: $color-error;
    font-family: OpenSans;
  }

  .login-error-container {
    height: 60px;
    color: $color-error;
    font-size: 12px
  }
}
