@import "../../../../../../../common/variables.scss";
@import "../../../../../../../common/helper.scss";

.create-group-form {
  width: 700px;
  max-width: 100%;
  overflow: hidden scroll;
}

.group-title {
  font-size: 14px;
  border-radius: 0px;
}

.search-employee-input-bg {
  background-color: $color-WhiteMetalLighter;
}

.title-required-error {
  font-size: 12px;
}

.form-control:focus {
  border: 1px solid $color-ElectricViolet;
  box-shadow: none;
}

.description-text-area {
  font-family: OpenSansRegular;
  font-size: 14px;
  min-height: 180px;
  border: 1px solid $color-ChathamsBlue;
}

.create-group-field-header {
  font-size: 14px;
  font-family: OpenSansRegular;
  color: $color-DoveGray;
}

.search-margin {
  margin-left: em(-7);
}
