@import '../../../../common/helper.scss';
@import '../../../../common/variables.scss';

.cost-report-text {
  margin-top: em(7);
}

ul {
  padding-left: em(19);
}

.bullet-color-techical::marker {
  color: $color-secondary;
  float: left;
  width: em(230);
}

.bullet-color-management::marker {
  color: $color-PaleVioletRed;
  float: left;
  width: em(230);
}

.bullet-color-soft::marker {
  color: $color-CreamCan;
  float: left;
  width: em(230);
}

.bullet-color-other::marker {
  color: $color-OceanGreen;
  float: left;
  width: em(230);
}

.cost-report-chart {
  float: right;
  margin-right: em(90);
}

.cost-report-training-cost {
  float: left;
}

.cost-value {
  text-align: right;
  float: right;
  font-weight: bold;
}
.cost-name {
  text-align: left;
  float: left;
}
.cost-report-table-footer {
  background-color: $color-CulturedPearl;
}
