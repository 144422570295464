.user-import-steps {
  width: 500px;
  max-width: 100%;
  display: flex;
  flex-direction: column;

  &-header {
    padding: 1rem;
  }

  &-content {
    padding: 1.5rem 2rem;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;

    &-title {
    }
  }
}
