@import '../../../../../common/variables.scss';

.content-section-option {
  border: 1px dashed $color-border-second;
  border-radius: 0.5rem;
  width: 100%;
  height: 100%;
  padding: 1rem;
  justify-content: center;
  align-items: center;
  text-align: center;

  &-btn {
    border-radius: 0.5rem;
    height: 110px;
  }

  &:hover {
    border-color: $color-text;
  }

  &-icon {
    font-size: 2rem !important;
    color: $color-icon-second;
  }

  &-label {
    font-weight: bold;
    font-size: 14px;
  }
}
