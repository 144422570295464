@import '../../../common/variables.scss';
@import '../../../common/helper.scss';

.training-details-header {
  &-image {
    height: 50px;
  }

  &-text {
    font-weight: bold;
    letter-spacing: 0.1rem;
  }

  &-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    max-width: 100%;

    .training-details-title {
      font-size: 1.25rem;
      padding-left: 0.1rem;
      text-transform: capitalize;
      color: $color-text;
    }

    .training-details-location {
      color: $color-secondary;
      font-family: OpenSans;
      font-size: 14px;
    }

    .training-details-creator {
      font-family: OpenSans;
      font-size: 14px;
    }

    .rating-value {
      font-size: 12px;
    }
  }

  .training-details-actions {
    margin-left: auto;
  }
}
