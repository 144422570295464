
@import "../../../../common/variables.scss";

.assignees-tab-search {
  margin-right: auto;
}

.assignees-tab-list {
  box-shadow: 0px 10px 20px #b7b7b766;
  border: 1px solid $color-whisper;
  border-radius: 6px;
}