@import '../../../common/helper.scss';

.solution-role-card-title {
  @include landingPageSectionSubTitle();
  text-align: center;
  font-weight: normal;
}

.solution-role-card-image{
  margin-top: em(30);
  height: em(150);
}