@import '../../../common/variables.scss';

.dashboard-section-header {
  display: flex;
  flex-direction: row;
  padding: 15px 20px 10px 20px;
  gap: 20px;

  h4 {
    font-size: 16px;
    flex: 1;
    font-weight: normal;
  }

  .dashboard-section-link-btn {
    display: flex;
    align-items: center;
    gap: 5px;
    color: $color-text;
    text-decoration: none;
    font-size: 12px;
  }
}

.dashboard-section-header-divider {
  margin: 0px 15px;
}

.dashboard-section-content {
  flex: 1;
  overflow: auto;
}