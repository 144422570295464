@import '../../../common/variables.scss';

.navbar-profile-avatar {
  width: 40px;
  height: 40px;
  color: white;
  font-size: 16px;
  font-weight: bold;
}

.navbar-profile-menu-name {
  padding: 0.5rem 1rem;
  font-size: 14px;
  background-color: $color-LightSilver;
  color: $color-text;
  font-weight: bold;
  pointer-events: none;
}

.navbar-profile-menu-logout {
  padding: 0.5rem 1rem;
  font-size: 14px;
  color: $color-ChathamsBlue;
  font-weight: bold;
  display: flex;
  gap: 0.5rem;

  &:hover {
    background-color: transparent;
    text-decoration: underline;
  }
}
