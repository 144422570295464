@import '../../common/variables.scss';
@import '../../common/helper.scss';

.request-demo-form-container {
  width: 1200px;
  max-width: 100%;
  overflow: hidden;
}

.request-demo-form-image {
  width: 100%;
  height: 100%;
  object-fit: cover;

  &-small {
    @extend .request-demo-form-image;
    height: 200px;
  }
}

.request-demo-form-content {
  height: 100%;
  padding: 2rem 4rem;
  flex-direction: column;
  gap: 1.5rem;
  align-items: center;

  &-small {
    @extend .request-demo-form-content;
    padding: 2rem;
  }

  .request-demo-form-title {
    font-size: 22px;
    font-weight: 600;
    text-align: center;
  }
}

.request-demo-form {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  width: 400px;
  max-width: 100%;
  height: 100%;

  .request-demo-form-btn {
    margin-top: 1.5rem;
  }
}

.request-demo-form-btn-group {
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1.5rem;

  & > * {
    flex: 1;
  }
}

.request-demo-form-server-error {
  font-size: 12px;
  color: $color-error;
}

.request-demo-form-disclaimer {
  padding-top: 1.5rem;
}

.request-demo-form-success-message {
  &-title {
    font-size: 38px;
    font-weight: 600;
    text-align: center;
  }

  &-text {
    font-size: 14px;
    text-align: center;
  }
}
