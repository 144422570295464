.policy-section {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  padding: 0 1.25rem;;

  h2 {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 1.875rem;
    padding-left: 2.8rem;
    text-indent: -2.8rem;
  }

  p, a {
    word-wrap: break-word;
  }

  & > :not(h2) {
    margin-left: 2.5rem !important;
  }

  & > *:not(:last-child) {
    margin-bottom: 1.875rem !important;
  }

  h2::before {
    content: url('../../../common/images/product-card-check.svg');
    margin-right: 1rem;
    vertical-align: -16%;
  }
}
