@import '../../../../common/variables.scss';

.agenda-item-time {
  font-weight: bold;
  color: $color-secondary;
  font-size: 14px;
}

.agenda-item-topic {
  font-size: 14px;
}
