@import '../../common/variables.scss';
@import '../../common/helper.scss';

.login-microsoft-btn {
  width: 250px;
  background-color: $color-White;
  border: 1px solid $color-GoogleBorder;
  border-radius: 16px;
  padding: 5px 10px;
  display: flex;
  flex-direction: row;
  align-items: center;

  &:hover {
    background-color: $color-GoogleBackgroundHovered;
    border-color: $color-GoogleBorderHovered;
  }
  
  img {
    height: 14px;
  }

  p {
    flex: 1;
    font-size: 14px;
    font-family: OpenSans !important;
    letter-spacing: 0.25px;
    font-family: 'Segoe UI', sans-serif;
    font-weight: 600;
    text-overflow: ellipsis;
    color: $color-BalticSea !important;
    text-transform: none !important;
  }
}
