@import '../../../common/variables.scss';
@import '../../../common/helper.scss';

.feature-card {
  @include card;
  padding: 30px 40px 20px 40px;
  width: 340px;
    
  &-small {
    @extend .feature-card;
    width: 100%;
  }

  .feature-card-image {
    height: 120px;
    width: 100%;
  }
  
  .feature-card-title {
    margin-top: em(20);
    @include landingPageSectionSubTitle();
    text-align: center;
    color: $color-primary;
  }
  
  .feature-card-description {
    margin-top: em(20);
    @include landingPageSectionText();
    text-align: center;
  }
}
  