@import '/src/common/variables.scss';

.search-field {
  height: 37px;

  & .custom-text-field-input-container {
    border-radius: 2rem;
    margin-top: 0 !important;
    height: 100%;
  }

  & .custom-text-field-input {
    padding: 0 1rem;
    font-size: 14px;
    height: 100%;
  }
}
