.speakers-details {
  width: 100%;
}

.speakers-container {
  width: 100%;
}

.img-no-speakers {
  height: 64px;
  object-fit: contain;
}

.speakers-container {
  overflow-x: auto;
  max-width: 100%;
}