@import '../../../../common/variables.scss';

.speaker-item {
  max-width: 130px;
}

.speaker-avatar {
  width: 58px;
  height: 58px;
}

.speaker-name {
  font-size: 12px;
  font-weight: bold;
  color: $color-secondary;
}

.speaker-job-title {
  font-size: 12px; 
}

.speaker-company {
  font-size: 12px; 
  font-weight: bold;
}
