@import '../../../common/variables.scss';
@import '../../../common/helper.scss';

.table-wrapper {
  overflow-x: auto;
}

.cookie-table {
  display: table;
  width: 100%;
  min-width: 650px;
  border-collapse: collapse;
}

.cookie-table td {
  width: 30%;
  height: em(50);
  text-align: left;
  border: em(1) solid darkgrey;
  padding: em(5);
}

/* nth-child(1) = the first td in each tr */
.cookie-table td:nth-child(1) {
  width: 20%;
}

/* the second */
.cookie-table td:nth-child(2) {
  width: 16%;
}

/* the third */
.cookie-table td:nth-child(3) {
  width: 60%;
}
