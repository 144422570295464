@import '../../common/variables.scss';
@import '../../common/helper.scss';

.home-section-container {
  overflow: hidden;
  margin-top: 6rem;

  p {
    padding-top: 18px;
    font-size: 26px;
  }
}

.home-section-inner-container {
  display: flex;
}

.home-section-content {
  padding: 0 2rem 0 6rem;
  font-family: Montserrat;
  color: $color-text;

  h1 {
    font-size: 52px;
    line-height: 62px;
    font-weight: bold;
  }
}

.home-section-image {
  height: 100%;
  position: relative;

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}

.play-video {
  cursor: pointer;
  position: absolute;
  top: 50%;
  left: 50%;
  align-items: center;
  transform: translateX(-50%) translateY(-50%);

  .play-video-icon {
    color: $color-text;
  }

  .play-video-text {
    color: $color-text;
    font-weight: bold;
    width: max-content;
  }
}

.play-video:hover {
  .play-video-icon,
  .play-video-text {
    color: $color-Emperor;
  }
}

.home-section-video {
  width: 100%;

  .iframe-container {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
  }

  iframe {
    border: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.landing-page-home-button-request {
  width: fit-content;
  margin-top: 36px;
  @include primaryBtn;
}

.landing-page-home-button-login {
  @include secondaryBtn;
}

@media (max-width: 1200px) {
  .home-section-container {
    margin-top: 2rem;
  }

  .home-section-content {
    padding: 3rem 2rem 0 0;
  }

  .home-section-sub-content {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
  }
}

@media (max-width: 900px) {
  .home-section-inner-container {
    flex-direction: column;
    flex-direction: column-reverse;
  }

  .home-section-content {
    padding: 2rem 0 0 0;
  }
}

@media (max-width: 480px) {
  .home-section-container {
    h1 {
      font-size: 42px;
      line-height: 48px;
    }

    p {
      padding-top: 0;
      font-size: 20px;
    }
  }
}
