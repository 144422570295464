@import '../../../common/variables.scss';
@import '../../../common/helper.scss';

.profile-section {
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  color: $color-text;

  .profile-section-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;

    .profile-section-avatar-container {
      cursor: pointer;
      position: relative;

      &:hover {
        .profile-section-edit-avatar-btn {
          position: absolute;
          bottom: 0;
          right: 0;
          visibility: visible;
        }
      }

      .profile-section-avatar {
        @include elevation;
        width: 160px;
        height: 160px;
        font-size: 48px;
      }

      .profile-section-edit-avatar-btn {
        visibility: hidden;
        position: absolute;
        width: 30px;
        height: 30px;
      }
    }

    .profile-section-username {
      text-align: center;
      font-size: 20px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
    }
  }

  .profile-section-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 0px 20px;

    &-small {
      @extend .profile-section-content;
      align-items: center;
      text-align: center;
    }

    .profile-section-data {
      &-title {
        font-size: 12px;
      }

      &-text {
        font-size: 14px;
        font-weight: bold;
      }
    }

    .profile-section-manager-data {
      display: flex;
      flex-direction: row;
      gap: 10px;
      margin-top: 5px;

      &-small {
        @extend .profile-section-manager-data;
        flex-direction: column;
        align-items: center;
      }

      .profile-section-manager-avatar {
        @include elevation;
        width: 36px;
        height: 36px;
        font-size: 14px;
      }
    }
  }

  .profile-section-footer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }
}