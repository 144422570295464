@import '../../common/variables.scss';

.app-header {
  background: transparent
    linear-gradient(270deg, $color-ChathamsBlue 0%, $color-Astral 100%) 0% 0%
    no-repeat padding-box;
  white-space: nowrap;

  .navbar-toolbar {
    gap: 2rem;
  }
}

.navbar-logo {
  flex-grow: 1;
}

.navbar-logo-text {
  color: $color-White;

 &:hover {
  text-decoration: none;
  color: $color-White;
 }
}
