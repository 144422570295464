@import '../../common/variables.scss';

.full-page-preloader-container {
  position: fixed;
  z-index: 2147483647;
  width: 100%;
  height: 100%;
  background: $color-White;
  display: flex;
  align-items: center;
  justify-content: center;
}
