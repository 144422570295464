@import '../../../../../../../../common/variables.scss';

.create-gorup-button {
  height: 36px;
  background-color: $color-primary;
  border-radius: 6px;
  color: white;
  font-size: 12px;
  font-family: OpenSansSemiBold;
  border: none;
}

.create-gorup-button:hover {
  background-color: $color-Crusta;
}

.create-gorup-button:disabled {
  background-color: $color-WhiteMetal;
}

.cancel-create-group-button {
  height: 36px;
  background-color: white;
  border-radius: 6px;
  border: 1px solid $color-ChathamsBlue;
  color: $color-ChathamsBlue;
  font-size: 12px;
  font-family: OpenSansSemiBold;
}

.cancel-create-group-button:hover {
  background-color: $color-ChathamsBlue;
  color: white;
}

.cancel-create-group-button:disabled {
  background-color: white;
  color: $color-WhiteMetal;
  border: 1px solid $color-WhiteMetal;
}
