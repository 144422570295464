@import '../../common/variables.scss';

.path-form {
  &-header {
    align-items: center;

    &-btn {
      color: $color-text;
      font-size: 18px;
    }

    &-title {
      font-weight: bold;
      font-size: 18px;
    }
  }
}
