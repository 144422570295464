@import '../../common/variables.scss';
@import '../../common/helper.scss';

.about-us-section-container {
  @include landingPageSectionContainer();
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  row-gap: 40px;
  column-gap: 120px;
  justify-content: center;

  &-small {
    @extend .about-us-section-container;
    @include landingPageSectionContainerSmall;
    row-gap: 30px;
  }
}

.about-us-section-data-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  min-width: 320px;
  max-width: 100%;

  .about-us-section-data-title {
    @include landingPageSectionTitle();
    text-align: start;
  }
  
  .about-us-section-data-sub-title {
    @include landingPageSectionText();
  }
  
  .about-us-section-data-description {
    @include landingPageSectionText();
    padding-top: 20px;
    line-height: 30px;
  }
  
  .about-us-section-data-footer {
    flex: 1;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-end;
    justify-content: space-between;
    margin-top: 30px;
    gap: 30px;
  
    .about-us-section-learn-more-btn {
      @include secondaryBtn;
    }

    .about-us-section-strypes-logo {
      height: em(55);
    }
  }
}

.about-us-section-image {
  width: 50%;
  flex: 1;
  object-fit: cover;

  &-small {
    @extend .about-us-section-image;
    width: 100%;
  }
}