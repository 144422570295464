@import '../../variables.scss';

.description-markdown {
  min-height: 300px;
  height: 100% !important;
  max-width: 100%;
  background-color: $color-input;
  border-radius: 0.5rem;
  box-shadow: none;
  overflow: hidden;
  font-size: 14px;

  .w-md-editor-text {
    height: 100%;
  }

  .w-md-editor-toolbar {
    padding: 0.3rem 0.5rem;
    border-color: $color-divider;
    background-color: $color-input;
  }

  textarea {
    padding: 0.5rem 13px;
    color: $color-text;
    resize: none;
  }

  .w-md-editor-bar {
    display: none;
  }
}
