@import '../../../common/helper.scss';

.solution-card-container {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: em(20);
}

.solution-card-data-container {
  display: flex;
  flex-direction: column;
  gap: em(15);
  flex: 1;

  .solution-card-title {
    @include landingPageSectionSubTitle();
  }

  .solution-card-description {
    @include landingPageSectionText();
  }
}
