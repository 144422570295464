@import '../../common/variables.scss';
@import '../../common/helper.scss';

.landing-footer-container {
  font-family: Montserrat;
  margin-top: 120px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  ul {
    padding-inline-start: 0;
  }

  &-small {
    @extend .landing-footer-container;
    margin-top: 80px;
  }

  hr {
    width: 100%;
    border-top: 0.5px solid #b0bec5;
  }

  .footer-main,
  .footer-policy {
    width: 100%;
    max-width: 1200px;
    padding: 1rem 1.25rem;
    display: flex;
    justify-content: space-between;
  }

  .footer-main {
    gap: 2rem;

    h5 {
      font-weight: 600;
      margin-bottom: 1rem;
    }

    & > nav {
      display: flex;
      flex-wrap: wrap;
      gap: 1rem 4rem;

      ul li {
        margin-top: 1rem;
      }
    }

    flex-wrap: wrap;

    & > div {
      width: 16rem;

      .upskillportal-logo {
        text-align: left;
        margin-bottom: 1rem;
      }
    }

    & button {
      border: none;
      background: none;
      padding: 0;
    }
  }

  .footer-policy {
    flex-direction: row;
    flex-wrap: wrap;
    column-gap: 1rem;

    .ltd {
      font-weight: bold;
    }

    a {
      color: $color-ElectricViolet;
    }
  }
}

.landing-footer-container .nav .nav-item {
  .nav-link {
    color: $color-text;
    padding-left: 0;
  }
}

.landing-footer-btn {
  text-align: start;
}
