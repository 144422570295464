@import '../../../common/variables.scss';
@import '../../../common/helper.scss';

.pricing-card {
  @include card;
  width: 270px;
  padding: 30px;
  color: $color-text;
  gap: 2rem;

  &-small {
    @extend .pricing-card;
    width: 100%;
    padding: 20px;
    gap: 20px;
  }

  .pricing-card-title {
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    font-family: Montserrat;
  }

  .pricing-card-body {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .pricing-card-personalized-offer {
      font-family: Montserrat;
      color: $color-primary;
      font-weight: bold;
      font-size: 30px;
      text-align: center;
    }

    .pricing-card-price-container {
      text-align: center;
    }

    .pricing-card-price {
      font-family: Montserrat;
      color: $color-primary;
      font-weight: bold;
      font-size: 42px;
    }

    .pricing-card-plan {
      font-family: Montserrat;
      font-size: 16px;
    }
  }

  .pricing-card-request-button {
    @include secondaryBtn;
    font-weight: 500;
    font-size: 18px;
    font-family: Montserrat;
  }
}
