@import '../../../../common/variables.scss';

.training-card-icon {
  left: 1.4rem;
  top: -0.6rem;
  width: 2.625rem;
  height: 2.625rem;
  border-radius: 6px;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $color-KindaBlue;
}
