@import '../../../common/variables.scss';

.navbar-links {
  gap: 1.25rem;

  &-link {
    font-size: 14px;
    color: $color-White;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5rem 0;

    &:hover {
      color: $color-White;
      text-decoration: none;
      text-decoration: solid underline $color-White 3px;
      text-underline-offset: 0.6rem;
    }

    &.active {
      color: $color-White;
      font-weight: bold;
      text-decoration: solid underline $color-White 3px;
      text-underline-offset: 0.6rem;
    }
  }
}
