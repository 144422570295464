.login-page {
    background: url('../../common/images/background-image.png');
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    overflow-y: auto;
    overflow-x: hidden;

    .login-page-container {
      max-height: 100%;
      max-width: 100%;

      .login-page-content {
        border-radius: 16px;
        overflow: hidden;
        max-width: 100%;
        max-height: 100%;
      }
    }
  }