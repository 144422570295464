.employee-table-header {
  font-size: 18px;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
}

.employees-table-header-title {
  font-weight: bold;
  font-size: 18px;
}

.employee-table-header-btns {
  margin-left: auto;
  flex-direction: row;
  flex-wrap: wrap;
}

.archive-employee-loader-cotaniner {
  text-align: center;
}
