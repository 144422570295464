.agenda-speakers-form-agenda {
  height: 300px;
}

.agenda-speakers-form-speakers {
  height: 300px;
}

// Medium sized screen
@media (max-width: 900px) {
  .agenda-speakers-form-agenda {
    height: auto;
    max-height: 300px;
  }

  .agenda-speakers-form-speakers {
    height: auto;
    max-height: 300px;
  }
}
