.event-details {
  width: 1200px;
  max-width: 100%;
  min-height: 741px;
  max-height: 100%;
}

.event-details-btn-link {
  text-decoration: underline;

  &:hover {
    text-decoration: underline;
  }
}

.event-details-section {
  height: 250px;
}

// Medium sized screen
@media (max-width: 900px) {
  .event-details-section {
    height: auto;
    max-height: 250px;
  }
}