@import './helper.scss';

$color-StrypesOrange: #fe9c0e;
$color-StrypesOrangeSecondary: #fe9c0e;
$color-StrypesBlack: #252525;

$color-StrypesWhite: #ffffff;
$color-StrypesGrey: #6c757d;
$color-StrypesBalticSea: rgba(52, 58, 64, 1);
$color-StrypesBalticSeaOpacity: rgba(52, 58, 64, 0.8);
$font-DefaultFont: Ubuntu;
$opacity-default: 0.25;
$opacity-hover: 1;

$training-details-button-width: em(236);
$training-details-button-small-width: 90%;

$dashboard-data-container-size: 62px;

$color-Black: #000;
$color-Black-Opacity: rgba(0, 0, 0, 0.3);
$color-MineShaft: #252525;
$color-Emperor: #525252;
$color-White: #ffffff;
$color-DarkSilver: #b8b8b8;
$color-Silver: #cccccc;
$color-GrayNickel: #c2c2c2;
$color-Gray: #929292;
$color-SilverChalice: #a6a6a6;
$color-LightSilver: #b8b8b81c;
$color-Alabaster: #fafafa;
$color-Nobel: #b6b6b6;
$color-WestSide: #fe9c0e;
$color-primary: #eb6012;
$color-Mango: #fee2e2;
$color-DarkerMango: #fcb4b4;
$color-DoveGray: #707070;
$color-DarkerDoveGray: #656565;
$color-Boulder: #7b7b7b;
$color-error: #f93c3c;
$color-RadicalRed: #ff435d;
$color-CaribbeanGreen: #00c29b;
$color-DodgerBlue: #388dff;
$color-ChathamsBlue: #0f596e;
$color-Scooter: #25c7dd;
$color-BlizzardBlue: #afe4ec;
$color-BlizzardBlueLighter: #bce0e5d5;
$color-ChathamsBlue-Opacity: rgba(15, 89, 110, 0.8);
$color-Astral: #30889c;
$color-secondary: #00aac2;
$color-secondary-lighter: #d9f2f6;
$color-ElectricViolet: #4a38ff;
$color-ParisDaisy: #ffee5f;
$color-VisVis: #fff6a8;
$color-warning: #fee60e;
$color-Shamrock: #41e277;
$color-Anakiwa: #97c3ff;
$color-Persimmon: #ff5757;
$color-Geyser: #dee2e6;
$color-whisper: #eaeaea;
$color-Tundora: #434343;
$color-Nevada: #546365;
$color-StormDust: #616160;
$color-Sinbad: #95cbd3;
$color-WhiteMetal: #d0d0cf;
$color-WhiteMetalLighter: #d0d0cf4d;
$color-Whitesmoke: #f5f5f5;
$color-text: #424242;
$color-divider: #c4c4c4;
$color-CulturedPearl: #f6f6f6;
$color-CulturedPearlLighter: #f4f4f44d;
$color-Crusta: #f2874f;
$color-CrustaLight: #f2874fcc;
$color-AmericanGreen: #32b743;
$color-Seachange: #41879b;
$color-SeachangeLighter: #41879b22;
$color-Black-Pearl: #1b1e21;
$color-Midnight-Express: #00000033;
$color-EveningBlue: #2b2a3e;
$color-LavenderGrayLighter: #c4c8d066;
$color-dark: #00000029;
$color-BalticSea: #3c4043;
$color-CathodeGreen: #02b83f;
$color-PaleVioletRed: #da76b4;
$color-CreamCan: #f5c040;
$color-OceanGreen: #569b6d;
$color-PaperHeartLight: #f7dec94d;
$color-BlackLighter: #0000001f;
$color-AliceBlue: #eff2f4;
$color-UltramarineBlue: #0c24ef;
$color-GoogleBorder: #dadce0;
$color-GoogleBorderHovered: #d2e3fc;
$color-GoogleBackgroundHovered: #4285f40a;
$color-LightBlue: #e5f4f8;
$color-UltraweirdBlue: #0f596e;
$color-KindaBlue: #e8f7fc;
$color-input: #f6f6f9;
$color-border-second: #dbdbdb;
$color-icon-second: #bbc2e1;
