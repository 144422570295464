@import '../../../common/variables.scss';

.navbar-notifications-title {
  padding: 0.75rem 1rem;
  font-size: 14px;
  background-color: $color-LightSilver;
  color: $color-text;
  font-weight: bold;
  pointer-events: none;
}

.navbar-notifications-item {
  pointer-events: none;
  padding: 0;

  &:hover {
    background-color: transparent;
  }
}