@import '../../variables.scss';

.employee-card {
  padding: 1rem;
  flex-direction: row;
  align-items: center;

  &-avatar {
    width: 42px;
    height: 42px;
    font-size: 16px;
  }

  &-name {
    font-size: 14px;
    font-weight: bold;
  }

  &-job-title {
    font-size: 14px;
  }

  &-remove-btn-icon {
    font-size: 1rem;
    color: $color-text;
  }
}
