.meeting-form {
  padding: 0 2rem;
  flex: 1;

  &-container {
    height: 100%;
    padding: 1.5rem 0;
  }

  &-title {
    font-weight: bold;
    font-size: 18px;
    padding: 0 2rem;
  }

  &-footer {
    flex-direction: row;
    justify-content: flex-end;
    padding: 0 2rem;
  }
}
