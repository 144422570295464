.path-details-page {
  &-header {
    align-items: center;
    font-weight: bold;

    &-btn {
      font-size: 18px;
    }

    &-title {
      font-size: 18px;
      font-weight: bold;
    }
  }

  & .path-card {
    box-shadow: none;
    padding: 0;
  }

  &-description {
    white-space: pre-wrap;
    font-size: 14px;
  }
}
