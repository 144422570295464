@import '../../common/variables.scss';
@import '../../common/helper.scss';

.base-search-box-input-search {
  position: absolute;
  z-index: 1;
  top: 10px;
  cursor: default;
}

.search-box-input {
  font-size: 14px;
  color: $color-text;
}

.add-item {
  text-decoration: underline;
  font-size: 14px;
  color: $color-text;
  display: inline-block;
}

.search-input-required {
  background-color: $color-CulturedPearl !important;
  border: em(1) solid $color-WhiteMetal;
  color: $color-WhiteMetal;
}

.search-input-required:focus {
  background-color: white !important;
  color: $color-ChathamsBlue;
}

.item-required-error {
  font-size: em(14);
}

.item-search-bar .form-control::-webkit-input-placeholder {
  font-size: em(18);
  font-family: OpenSansRegular;
  color: $color-ChathamsBlue;
}

.item-search-bar-required .form-control::-webkit-input-placeholder {
  font-size: em(12);
  font-family: OpenSansRegular;
  color: $color-WhiteMetal;
}

.base-search-box-input-search-required {
  opacity: 0.7;
}

.type-icon {
  border-radius: 50%;
  border: em(1) solid $color-secondary;
}

.type-icon-white-border {
  border-radius: 50%;
  border: em(1) solid $color-StrypesWhite !important;
}

.rbt-input-wrapper {
  max-height: em(76);
  overflow: auto;
}

.rbt-input-multi .rbt-input-wrapper {
  overflow: auto;
  z-index: 1;

  &::-webkit-scrollbar {
    width: em(10);
    cursor: pointer;
    z-index: 2;
  }
}

.rbt-token {
  font-size: em(12);
  background-color: $color-Seachange;
  color: white;
  border-radius: em(6);
  padding: em(6) em(25) em(6) em(10);
  margin: em(2.5);
}

.rbt-token-remove-button {
  margin-top: em(4);
}

.dropdown-item.active {
  background-color: $color-Seachange;
}

.dropdown-item:hover {
  background-color: $color-SeachangeLighter;
  color: black;
}
