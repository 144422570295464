@import '../../common/variables.scss';
@import '../../common/helper.scss';

.features-section-container {
  @include landingPageSectionContainer;

  &-small {
    @extend .features-section-container;
    @include landingPageSectionContainerSmall;
  }
}

.features-section-title {
  @include landingPageSectionTitle;
}

.features-section-items-container {
  margin-top: 60px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: 60px;

  &-small {
    @extend .features-section-items-container;
    gap: 1.25rem;
  }
}