@import '../../../common/variables.scss';

.event-details-name {
  font-size: 12px;
  color: $color-text;
}

.event-details-value {
  font-size: 14px;
  font-weight: bold;
  color: $color-text;
}