@import '../../../common/variables.scss';

.drawer-toolbar {
  background: transparent
    linear-gradient(270deg, $color-ChathamsBlue 0%, $color-Astral 100%) 0% 0%
    no-repeat padding-box;
  min-height: 54px;
  height: 54px;
}

.drawer-list {
  background: transparent
    linear-gradient(270deg, $color-ChathamsBlue 0%, $color-Astral 100%) 0% 0%
    no-repeat padding-box;
  display: flex;
  flex-direction: column;
  padding: 0 !important;

  .drawer-profile-btn {
    display: flex;
    gap: 0.5rem;
    justify-content: center;
    color: $color-White;
    font-size: 14px;
    font-family: OpenSans;
    font-weight: bold;

    .drawer-profile-btn-avatar {
      width: 40px;
      height: 40px;
      color: $color-White;
      font-size: 16px;
    }
  }

  .drawer-profile-menu {
    background: transparent
      linear-gradient(270deg, $color-ChathamsBlue 0%, $color-Astral 100%) 0% 0%
      no-repeat padding-box;
    display: flex;
    flex-direction: column;
    width: 100%;

    .drawer-profile-menu-item {
      padding: 0.75rem 1rem;
      width: 100%;
      font-size: 14px;
      font-family: OpenSans;
      font-weight: bold;
      text-align: center;
      justify-content: center;
      display: flex;
      gap: 0.5rem;
      color: $color-ChathamsBlue;
      background-color: $color-LightBlue;
    }
  }

  .drawer-links-menu {
    background: transparent
      linear-gradient(270deg, $color-ChathamsBlue 0%, $color-Astral 100%) 0% 0%
      no-repeat padding-box;
    display: flex;
    flex-direction: column;
    width: 100%;

    .drawer-links-menu-link {
      padding: 0.75rem 1rem;
      width: 100%;
      justify-content: center;
      font-size: 14px;
      color: $color-White;
      text-transform: uppercase;
      text-align: center;
      font-family: OpenSans;

      &.active {
        color: $color-ChathamsBlue;
        background-color: $color-LightBlue;
        font-weight: bold;
        text-decoration: none;
      }

      &:hover {
        text-decoration: none;
      }
    }
  }
}
