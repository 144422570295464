@import '../../../../common/helper.scss';
@import '../../../../common/variables.scss';

.demo-request-table .MuiTableContainer-root {
  margin-left: em(94) !important;
  width: 90% !important;
}

.detail-information {
  color: $color-secondary;
  font-size: em(17);
}

.submission-date {
  max-width: em(50);
}

.approved-color {
  color: $color-CaribbeanGreen !important;
}

.rejected-color {
  color: $color-error !important;
}

.pending-color {
  color: $color-warning !important;
}

.action-column {
  width: em(200);
}

.demo-request-full-name {
  margin-top: em(7);
}

.column {
  color: $color-text !important;
  font-weight: bold !important;
  font-size: em(15) !important;
}

.comment {
  max-width: em(400);
  word-wrap: break-word;
}

.verticalLine {
  border-left: solid $color-Whitesmoke;
}

.MuiPaper-rounded {
  box-shadow: em(0) em(3) em(6) $color-dark !important;
  border: em(1) solid $color-whisper !important;
  border-radius: em(6) !important;
}

.submissions-title {
  font-size: 18px;
  font-weight: bold;
}