@import '../../../common/variables.scss';
@import '../../../common/helper.scss';

.training-details-card {
  gap: 1rem;
  padding-top: 0.75rem;
  flex: 25%;

  &-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;

    p:first-of-type {
      font-size: 14px;
    }

    p:last-child {
      margin-left: auto !important;
    }
  }
}
