@import '../../variables.scss';

.success-message {
  max-width: 100%;
  padding: 1.5rem 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;

  &-text {
    text-align: center;
    font-size: 14px;
  }
}