.path-item-status-box {
  border-radius: 50%;
  width: 34px;
  height: 34px;
}

.path-item-status-box-tooltip {
  &-title {
    font-size: 12px;
    font-weight: bold;
  }

  &-text {
    font-size: 12px;
  }
}
