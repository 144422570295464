.page-container {
  scroll-behavior: smooth;
  overflow: auto;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.page-content {
  flex: 1;
}
