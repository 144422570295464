@import '../../../common/variables.scss';

.notification {
  padding: 0.75rem 1rem;
  font-size: 14px;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;

  .notification-body {
    flex: 1;
    display: flex;
    width: 100%;
    flex-direction: row;
    gap: 1rem;

    .notification-avatar {
      width: 40px;
      height: 40px;
      font-size: 16px;
    }

    .notification-content {
      display: flex;
      flex: 1;
      flex-direction: column;
      overflow: hidden;

      &-header {
        color: $color-text;
        font-size: 14px;

        &-sender {
          font-weight: bold;
        }
      }

      &-body {
        text-align: start;
        pointer-events: auto;
        justify-content: flex-start;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }

        & > * {
          font-size: 14px;
          color: $color-primary;
        }
      }

      &-administration-link {
        font-weight: normal;
        pointer-events: auto;
      }
    }
  }

  .notification-comment {
    pointer-events: auto;
    border: none !important;
    border-radius: 0 !important;
    box-shadow: none !important;
    font-size: 14px;

    &:before {
      display: none;
    }

    &-summary {
      padding: 0;
      min-height: 0;

      .MuiAccordionSummary-content {
        margin: 0;
      }
    }

    &-btn-icon {
      color: $color-text;
    }

    &-details {
      padding: 0;
      font-weight: normal;
      overflow: hidden;
      white-space: normal;
    }
  }

  .notification-actions-btn {
    pointer-events: auto;
  }
}
