@import '../../common/variables.scss';
@import '../../common/helper.scss';

.upskillportal-logo {
  font-family: OpenSans;
  font-weight: bold;
  color: $color-ChathamsBlue;
  text-align: center;

  p {
    font-size: 1.5rem;
    line-height: 1.8rem;
  }

  mark {
    color: $color-primary;
    background-color: transparent;
    padding: 0;
  }
}
