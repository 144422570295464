.time-frame-section {

  &-title {
    font-size: 1rem;
    font-weight: bold;
  }
}

.path-duration-text-field {
  max-width: 200px;
}