@import '../../../common/variables.scss';

.notification-drawer-toolbar {
  min-height: 54px;
  height: 54px;
}

.notification-drawer-list {
  overflow: auto;

  &-title {
    padding: 0.75rem 1rem;
    font-size: 14px;
    background-color: $color-LightSilver;
    color: $color-text;
    font-weight: bold;
    pointer-events: none;
  }

  &-item {
    padding: 0;
  }
}
