@import '../../common/helper.scss';

.pricing-section-container {
  @include landingPageSectionContainer();

  &-small {
    @extend .pricing-section-container;
    @include landingPageSectionContainerSmall;
  }
}

.pricing-section-title {
  @include landingPageSectionTitle();
  margin-bottom: 25px;
}

.pricing-section-sub-title {
  text-align: center;
  color: #424242;
  font-size: 26px;
  font-family: Montserrat;
}

.pricing-section-items-container {
  margin-top: 70px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 40px;
  justify-content: center;

  &-small {
    @extend .pricing-section-items-container;
    gap: 1.25rem;
    margin-top: 35px;
  } 
}