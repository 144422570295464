@import '../../common/variables.scss';
@import '../../common/helper.scss';

.login-form-main-container {
  width: 900px;
  max-width: 100%;
  max-height: 100%;
  min-height: 600px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  overflow: auto;

  &-small {
    @extend .login-form-main-container;
    flex-direction: column;
  }

  .login-form-image {
    width: 50%;
    flex: 1;
    object-fit: cover;

    &-small {
      height: 200px;
      width: 100%;
      object-fit: cover;
    }
  }

  .login-form-content {
    background-color: white;
    width: 50%;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 25px 50px;
    gap: 50px;
    overflow: auto;

    &-disclaimer {
      font-size: 14px;
    }

    &-small {
      @extend .login-form-content;
      width: 100%;
      gap: 30px;
      padding: 1rem 2rem 2rem 2rem;
    }

    .upskillportal {
      margin-top: 40px;
      text-align: center;

      &-small {
        @extend .upskillportal;
        margin-top: 10px;
      }

      &::before {
        font-family: OpenSansBold;
        font-size: 35px;
        color: $color-primary;
        content: 'Up';
      }

      &::after {
        font-family: OpenSansBold;
        font-size: 35px;
        color: $color-ChathamsBlue;
        content: 'skill Portal';
      }
    }

    .login-form-switch-btn {
      background: none;
      border: none;
      font-size: 14px;
      color: $color-text;
      font-family: OpenSans;
    }
  }
}
