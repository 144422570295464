@import '../../common/variables.scss';
@import '../../common/helper.scss';

.user-guide {
  border: none;
  border-radius: 1rem;
  width: 500px;
  max-width: 100%;
  min-height: 600px;
  max-height: 100%;
  padding: 1px;
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.user-guide-image {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: block;
  border-radius: 1rem 1rem 0 0;
}

.body {
  flex: 1;
}

.user-guide-title {
  padding: 0 30px;
  font-size: 28px;
  font-family: OpenSans;
  font-weight: bold;
  text-align: center;
}

.user-guide-description {
  padding: 0 30px;
  font-size: 14px;
  line-height: 22px;
  font-family: OpenSans;
  text-align: center;
  color: $color-text;
  width: 100%;

  margin-left: auto;
  margin-right: auto;
  display: block;
}

.footer {
  display: flex;
  flex-direction: row;
  padding: 20px;
  gap: 10px;
  align-items: center;
}

.page-counter {
  font-size: 14px;
  font-family: OpenSans;
  flex: 1;
}

.page-counter-slices {
  display: flex;
  flex-direction: row;
  gap: 3px;
  margin: 5px 5px 0 5px;
}

.page-counter-slice {
  height: 4px;
  flex: 1;
  background-color: $color-Silver;
}

.page-counter-slice-active {
  background-color: $color-primary;
}
