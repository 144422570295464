.events-page {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  overflow: visible;
  padding: 2rem 0;

  &-header {
    padding: 0 2rem;

    .create-event-btn {
      margin-left: auto;
    }
  }

  &-not-found {
    font-size: 14px;
    text-align: center;
    padding: 5rem 0;
  }

  .events-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 0 2rem;
    overflow: visible;
    width: 100%;

    &-loading {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .events-page-infinite-scroll {
      overflow: visible !important;
    }
  }
}
