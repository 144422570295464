@import '../../../../../../common/variables.scss';

.file-form {
  padding: 0 2rem;
  flex: 1;

  &-container {
    height: 100%;
    padding: 1.5rem 0;
  }

  &-title {
    font-weight: bold;
    font-size: 18px;
    padding: 0 2rem;
  }

  &-footer {
    flex-direction: row;
    justify-content: flex-end;
    padding: 0 2rem;
  }
}

.file-upload-hidden-input {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
}

.file-upload-input, .file-upload-hidden-input {
  cursor: pointer;
}

.file-upload-input-clear-btn-icon {
  font-size: 1rem;
  color: $color-text;
}

.file-upload-input-replace-btn {
  border: none;
  outline: none;
  background: none;
  font-size: 0.875rem;
  color: $color-ElectricViolet;
}
