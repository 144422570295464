@import '../../../../common/variables.scss';

.content-section {
  align-items: flex-start;

  &-title {
    font-size: 1rem;
    font-weight: bold;
  }

  &-options {
    flex-direction: row;
    flex-wrap: wrap;
  }

  &-list {
    width: 100%;

    &-container {
      width: 100%;
    }
  }
}
