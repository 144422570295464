@import '../../../../common/variables.scss';
@import '../../../../common/helper.scss';

.contact-send-success-container {
  margin: 0 auto;
  text-align: center;
  color: $color-text;
}

.contact-form-success {
  font: normal normal bold 35px/50px Montserrat;
  margin-top: em(60);
}

.contact-form-success-sub-1,
.contact-form-success-sub-2 {
  font: normal normal normal 16px/36px Montserrat;
}

.contact-form-success-sub-1 {
  margin-top: em(80);
}
