@import "../../../../../../../../common/variables.scss";
@import "../../../../../../../../common/helper.scss";

.group-member-container {
  border-radius: 10px;
  width: 150px;
  height: 170px;
  box-shadow: 0 3px 6px rgba($color-MineShaft, 0.5);
}

.group-member-img-container {
  width: 200px;
}

.group-member-names {
  font-size: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  color: $color-ChathamsBlue;
  white-space: pre-line;
  max-width: 180px;
  padding-left: 33px;
}
.group-member-job-title {
  font-size: 10px;
  color: $color-secondary;
  white-space: pre-line;
  max-width: 160px;
  padding-left: 38px;
}

.all-group-members-container {
  width: 100%;
  overflow-x: auto;
  padding-left: 10px;
  padding-bottom: 25px;
}

.all-group-members-container::-webkit-scrollbar {
  height: 12px;
}

.all-group-members-container::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: $color-WhiteMetalLighter;
}

.all-group-members-container::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: $color-WhiteMetal;
}

.remove-member {
  margin-right: em(21) !important;
}
