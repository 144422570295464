@import '../../../../common/variables.scss';

.upcoming-event-date-container {
  background-color: $color-LightBlue;
  border-radius: 0.75rem;
  width: $dashboard-data-container-size;
  min-width: $dashboard-data-container-size;
  height: $dashboard-data-container-size;
  min-height: $dashboard-data-container-size;
  align-items: center;
  justify-content: center;
}

.upcoming-event-date-day {
  color: $color-secondary;
  font-size: 28px;
  font-weight: bold;
  line-height: 1.2;
}

.upcoming-event-date-month {
  color: $color-secondary;
  font-size: 14px;
  line-height: 1;
}

.upcoming-event-title {
  font-size: 14px;
}

.upcoming-event-view-btn {
  font-size: 14px;
}
