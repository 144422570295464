@import './variables.scss';
@import './helper.scss';
@font-face {
  font-family: OpenSansBold;
  src: url('../resources/fonts/Open_Sans/OpenSans-Bold.ttf');
}

@font-face {
  font-family: OpenSansSemiBold;
  src: url('../resources/fonts/Open_Sans/OpenSans-SemiBold.ttf');
}

@font-face {
  font-family: OpenSansLight;
  src: url('../resources/fonts/Open_Sans/OpenSans-Light.ttf');
}

@font-face {
  font-family: OpenSansRegular;
  src: url('../resources/fonts/Open_Sans/OpenSans-Regular.ttf');
}

@font-face {
  font-family: OpenSans;
  src: url('../resources/fonts/OpenSans-VariableFont_wdth,wght.ttf');
}

@font-face {
  font-family: Montserrat;
  src: url('../resources/fonts/Montserrat-VariableFont_wght.ttf');
}

#root {
  display: flex;
  flex-direction: column;
  height: 100%;
}

*,
*:focus,
*:hover {
  outline: none;
}

button:focus {
  outline: 0;
}

body {
  overflow: hidden;
}

a {
  text-decoration: none;
  color: $color-White;
}

ol,
li {
  list-style-type: none;
  padding: 0;
}

a:hover {
  color: $color-Alabaster;
}

.invisible {
  visibility: hidden;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

// scrollbar
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-thumb {
  background: $color-Silver;
  border-radius: 20px;

  &:hover {
    background: $color-DarkSilver;
  }
}

::-webkit-scrollbar-track {
  background: $color-LightSilver;
}

.bg-portal-caribbean {
  background-color: $color-CaribbeanGreen;
}

.bg-portal-shamrock {
  background-color: $color-Shamrock;
}

.bg-portal-coral-red {
  background-color: $color-error;
}

.portal-border-radius {
  border-radius: em(50);
}

.portal-btn {
  opacity: 1;
  color: $color-Alabaster;
  text-transform: uppercase;
  font-size: em(14);
  @extend .portal-border-radius;
}

.portal-green-btn {
  background: $color-CaribbeanGreen;
  @extend .portal-btn;
}

.portal-green-btn:hover {
  background: $color-Scooter;
}

.portal-side-blue-btn {
  cursor: pointer;
  background: $color-secondary;
  color: $color-White;
  height: em(50);
  line-height: em(38);
  border-top-left-radius: em(30);
  border-bottom-left-radius: em(30);
  font-size: em(14);
  border: none;
}

.portal-side-blue-btn:hover {
  box-shadow: em(0) em(3) em(6) $color-MineShaft;
}

.portal-tango-btn {
  background: $color-primary;
  @extend .portal-btn;
}

.portal-tango-btn:hover {
  background: $color-WestSide;
  @extend .portal-btn;
}

.portal-primary-btn {
  background: transparent
    linear-gradient(90deg, $color-primary 0%, $color-WestSide 100%) 0% 0%
    no-repeat padding-box;
  @extend .portal-btn;
}

.portal-primary-btn:disabled {
  opacity: 0.7;
}

.portal-primary-btn:hover {
  color: $color-White;
  background: transparent
    linear-gradient(90deg, $color-WestSide 0%, $color-primary 100%) 0% 0%
    no-repeat padding-box;
}

.portal-secondary-btn {
  border-radius: em(50);
  font-size: em(14);
  font-family: OpenSansSemiBold;
  opacity: 1;
  border: em(1) solid $color-primary;
  color: $color-primary;
  width: 100%;
  background-color: $color-White;
}

.confirmation-btn {
  height: em(40);
  display: table-cell;
  vertical-align: middle;
}

.apply-btn {
  min-width: $training-details-button-width;
}

.portal-confirm-btn {
  @extend .portal-btn;
  background-color: $color-CaribbeanGreen;
  color: $color-White;
  border: none;
}

.portal-confirm-btn:hover {
  @extend .portal-btn;
  border: em(1) solid $color-CaribbeanGreen;
  color: $color-CaribbeanGreen;
  background-color: $color-White;
}

.portal-decline-btn,
.add-to-btn {
  @extend .portal-btn;
  font-size: em(14);
  border: em(1) solid $color-ChathamsBlue;
  color: $color-ChathamsBlue;
  background-color: $color-White;
}

.portal-decline-btn:hover,
.add-to-btn:hover {
  @extend .portal-btn;
  background-color: $color-ChathamsBlue;
  color: $color-White;
  border: none;
}

.portal-reject-btn,
.portal-delete-btn:hover {
  @extend .portal-btn;
  border: em(1) solid $color-Persimmon;
  color: $color-Persimmon;
  background-color: $color-White;
}

.portal-reject-btn:hover,
.portal-delete-btn {
  @extend .portal-btn;
  background-color: $color-Persimmon;
  color: $color-White;
  border: none;
}

.save-btn {
  @extend .portal-tango-btn;
  box-shadow: none;
  border: none;
  width: em(124);
  height: em(42);
}
.save-btn:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.portal-secondary-btn:hover,
.portal-secondary-btn-selected {
  color: $color-White;
  background-color: $color-primary;
}

.portal-secondary-btn:disabled {
  cursor: not-allowed;
  pointer-events: none;
}

.portal-clean-btn {
  background-color: $color-White;
  border: em(3) solid $color-primary;
  opacity: 1;
  color: $color-primary;
  text-transform: uppercase;
  font-size: em(14);
  padding-top: em(10);
  padding-bottom: em(10);
  padding-left: em(56);
  padding-right: em(56);
  @extend .portal-border-radius;
}
.portal-clean-btn:hover {
  color: $color-White;
  background: $color-primary;
}
.portal-clean-btn:focus {
  outline: none;
}

.portal-cancel-btn {
  color: $color-Astral;
  background: none;
  border: em(1) solid $color-Astral;
  text-transform: uppercase;
  font-size: em(14);
  @extend .portal-border-radius;
}

.portal-cancel-btn:hover {
  background-color: $color-Astral;
  color: $color-White;
}

.cancel-add-selected-btn {
  @extend .portal-cancel-btn;
  color: $color-White;
  border: em(1) solid $color-White;
  width: em(189);
  height: em(40);
  line-height: em(40);
}

.cancel-add-selected-btn:hover {
  color: $color-ChathamsBlue;
  border: em(1) solid $color-ChathamsBlue;
  background: $color-White;
  width: em(189);
  height: em(40);
  line-height: em(40);
}

.add-selected-trainings-btn {
  @extend .portal-tango-btn;
  border: none;
  width: em(189);
  height: em(40);
  line-height: em(40);
}

.add-selected-trainings-btn:disabled {
  opacity: 0.5;
  cursor: not-allowed;
  pointer-events: none;
}

.add-selected-trainings-btn:hover {
  border: none;
}

.portal-submit-note-btn {
  background-color: $color-DarkSilver;
  color: $color-White;
  border: em(1) solid $color-DarkSilver;
  text-transform: uppercase;
  font-size: em(12);
  @extend .portal-border-radius;
}

.portal-submit-note-btn-active {
  @extend .portal-tango-btn;
  border: none;
}

.portal-submit-note-btn:hover {
  background-color: $color-DarkSilver;
  color: $color-White;
}

.portal-nav-container {
  height: em(54);
  margin: 0 em(50);
}

.portal-nav-container-line {
  width: 100%;
  border-bottom: em(1) solid $color-text;
}

.portal-nav-item {
  cursor: pointer;
  font-size: 16px;
  color: $color-Astral;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: em(47);
  vertical-align: middle;
  font-family: OpenSansRegular;
}

.portal-nav-item:hover,
.portal-nav-item-selected {
  border-bottom: em(4) solid $color-secondary;
  color: $color-secondary;
  font-weight: bold;
}

.card-title {
  font-family: OpenSansSemiBold;
  border-bottom: em(1) solid $color-ChathamsBlue;
  height: em(58);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: em(20);
}

.form-heading {
  font-size: em(20);
  font-family: OpenSansBold;
}

.training-title {
  font-size: em(14);
}

.training-item-circle-common {
  position: absolute;
  width: em(34);
  height: em(34);
  border-radius: 50%;
  padding-top: em(4);
}

.training-item-circle {
  color: $color-White;
  background-color: $color-DarkSilver;
  @extend .training-item-circle-common;
}

.training-item-circle-first {
  @extend .training-item-circle-common;
  color: $color-MineShaft;
  background-color: $color-primary;
}

.gray-color {
  color: $color-Gray;
}

.color-gray-nickel {
  color: $color-GrayNickel;
}

.color-dove-gray {
  color: $color-DoveGray;
}

.color-pacific-blue {
  color: $color-secondary;
}

.color-chathams-blue {
  color: $color-ChathamsBlue;
}

.color-west-side {
  color: $color-WestSide;
}

.color-coral-red {
  color: $color-error;
}

.cursor-pointer {
  cursor: pointer !important;
}

.cursor-not-allowed {
  cursor: not-allowed !important;
}

.underline {
  text-decoration: underline;
}

.border-tango {
  border-color: $color-primary;
}
.border-shamrock {
  border-color: $color-Shamrock;
}
.border-green-carribbean {
  border-color: $color-CaribbeanGreen;
}

.border-pacific-blue {
  border-color: $color-secondary;
}

.inline-block {
  display: inline-block !important;
}

.white-color {
  color: $color-White;
}
.green-color {
  color: $color-CaribbeanGreen;
}
.blue-color {
  color: $color-DodgerBlue;
}
.blue-color:hover {
  color: $color-DodgerBlue;
}
.orange-color {
  color: $color-primary;
}
.storm-dust-color {
  color: $color-StormDust;
}
.silver-color {
  color: $color-Silver;
}
.light-mineshaft {
  color: $color-MineShaft;
}
.red-color {
  color: $color-error;
}

.pacific-blue-color {
  color: $color-secondary !important;
}

.chathams-blue-color {
  color: $color-ChathamsBlue !important;
}

.modal-backdrop.show {
  background: $color-ChathamsBlue;
  opacity: 0.98;
}

.xl-modal .modal-dialog {
  max-width: em(2165);
  width: 100%;
  padding: 0 em(20);
  box-sizing: border-box;
}

.large-modal .modal-dialog {
  max-width: em(1250);
  width: 100%;
  padding: 0 em(20);
  box-sizing: border-box;
}

.big-modal .modal-dialog {
  max-width: em(1600);
  max-height: em(700);
  width: 97%;
  height: em(1006);
}

.small-modal .modal-dialog {
  width: em(586);

  .modal-content {
    height: em(295);
    border: none;
    box-shadow: em(0) em(5) em(15) rgba(37, 37, 37, 0.5);
  }
}

.modal-content {
  border: none;
}

.medium-modal .modal-dialog {
  max-width: em(785);
  margin-top: 0;
}

textarea {
  resize: none;
}

// datepicker {
.DayPickerInput-OverlayWrapper {
  z-index: 1000;
}

.portal-textarea {
  font-size: em(12);
  font-family: 'OpenSansRegular';
  min-height: em(180);
  border: em(1) solid $color-secondary;
  color: $color-Astral;
}

.portal-textarea::placeholder,
.portal-textarea:focus {
  color: $color-Astral;
}

.regular-text {
  font-family: OpenSansRegular;
}

// errors

.error-text {
  color: $color-error !important;
  font-family: OpenSansRegular;
}

.error-text-bold {
  @extend .error-text;
  font-family: OpenSansBold;
}

.error-input {
  @extend .error-text;
  background: none !important;
  border-color: $color-error !important;
}

.error-input::placeholder,
.error-input:hover {
  color: $color-error !important;
  font-family: OpenSansRegular;
}

.error-input-bold {
  @extend .error-input;
  font-family: OpenSansBold;
}

.error-input-bold::placeholder,
.error-input-bold:hover {
  @extend .error-text;
  font-family: OpenSansBold;
}

// portal search input

.portal-search-input {
  border: em(1) solid $color-Astral;
  border-radius: em(50);
  opacity: 1;
  font-size: em(11);
  color: $color-ChathamsBlue;
  font-family: OpenSansSemiBold;
}

.portal-search-input::placeholder {
  color: $color-ChathamsBlue;
}

.portal-search-img {
  position: absolute;
  right: em(10);
  top: em(5);
}

.account-icon {
  background-color: $color-primary;
  border-radius: em(80);
}

.account-icon-not-available {
  border-radius: em(80);
  background-color: $color-MineShaft;
}

.selection-link {
  text-decoration: underline;
  font-family: OpenSansBold;
  font-size: em(14);
}

.choose-text {
  font-family: OpenSansBold;
  color: $color-MineShaft;
  font-size: em(14);
}

.added-text {
  color: $color-MineShaft;
  font-size: em(12);
  font-family: OpenSansRegular;
}

.item-type-common {
  border: em(1) solid $color-secondary;
  border-radius: em(50);
  font-size: em(14);
  font-weight: OpenSansBold;
  color: $color-secondary;
  background-color: $color-White;
}

.item-type {
  // min-width: em(195);
  @extend .item-type-common;
}

.item-type:hover,
.item-type-selected,
.item-type-selected:hover {
  @extend .item-type-common;
  background-color: $color-secondary;
  border: em(1) solid $color-secondary;
  color: $color-White;
}

.full-height {
  height: 100% !important;
}

.rounded-button {
  border-radius: em(30) !important;
}

.grayed {
  color: $color-DoveGray;
}

.new-line {
  white-space: pre-line;
}

.validation-error {
  @extend .color-coral-red;
  font-size: 12px;
}

.disabled {
  pointer-events: none;
  opacity: 0.5 !important;
}

.entry-title {
  border-left: none;
  border-right: none;
  border-top: none;
  text-align: left;
  font-family: OpenSansBold;
  font-size: em(20);
}

.entry-title-inactive {
  @extend .entry-title;
  color: $color-DarkSilver;
  border-bottom: em(1) solid $color-DarkSilver;
}

.entry-title-active {
  @extend .entry-title;
  color: $color-ChathamsBlue;
  border-bottom: em(1) solid $color-ChathamsBlue;
}

.buttons-container {
  height: em(67);
  display: flex;
  justify-content: center;
}

.path-type-icon-container,
.skill-type-icon-container {
  color: $color-secondary;
}

.search-img {
  top: em(3);
  position: absolute;
  right: em(15);
}

.font-family-bold {
  font-family: OpenSansBold;
}

.font-family-regular {
  font-family: OpenSansRegular;
}

.details-title {
  font-family: OpenSansBold;
  font-size: em(20);
  text-transform: capitalize;
  color: $color-ChathamsBlue;
}

.details-created-by {
  font-family: OpenSansRegular;
  font-size: em(14);
}
.details-created-by-name {
  font-family: OpenSansRegular;
  font-size: em(16);
}
.details-light-text {
  font-family: OpenSansLight;
  color: $color-Tundora;
  font-size: em(12);
}
.details-props {
  border-left: em(0.5) solid $color-Geyser;
  min-height: em(70);
}
.item-detail {
  align-items: center;
  height: 60%;
}
.location-icon {
  margin-bottom: em(5);
}

.label-heading {
  font-family: OpenSansRegular;
  font-size: em(16);
}

.label-heading-sm {
  font-family: OpenSansRegular;
  font-size: em(14);
}

.cursor-pointer {
  cursor: pointer;
}

.training-details-button,
.event-details-button {
  height: em(48);
  font-size: em(12);
  border-radius: em(6);
  border: none;
  text-transform: uppercase;
  padding: em(0) em(30);
}

// START - Event overview details buttons
.assign-event-button {
  background-color: $color-primary;
  color: $color-StrypesWhite;
  @extend .event-details-button;
}

.assign-event-button:hover {
  background: $color-WestSide;
}

.opacity-75 {
  opacity: 0.75 !important;
}

.opacity-50 {
  opacity: 0.5 !important;
}

p {
  margin: 0px !important;
}

p,
a,
li,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: OpenSans !important;
  margin-bottom: 0px;
}

hr {
  margin: 0;
}

a {
  color: $color-ElectricViolet;

  &:hover {
    color: $color-ElectricViolet;
  }
}

// Bootstrap Popover (for compatibility with MUI)
.popover {
  z-index: 1303 !important;
}

.section-title {
  font-size: 18px;
  font-weight: bold;
}

//Policy Pages
.policy-page {
  color: $color-text;
  display: flex;
  flex-direction: column;
  align-items: center;

  mark {
    background-color: yellow;
  }

  strong {
    font-style: italic;
  }

  & * {
    font-family: Montserrat !important;
  }

  h1 {
    font-size: 3.25rem;
    margin: 2.5rem 2.5rem;
    text-align: center;
  }

  li {
    list-style: outside;

    h6 {
      margin-bottom: 0.4rem;
    }
  }

  header {
    width: 100%;

    & > p:first-of-type {
      margin: 0 auto 5rem auto !important;
      max-width: 1200px;
      padding: 0 1.25rem;
      margin: 0 auto;
    }

    & > img {
      width: 100%;
      max-height: 550px;
      object-fit: cover;
    }
  }

  .alpha-list > li {
    list-style: upper-alpha;
  }

  .policy-section:not(.policy-section:last-of-type) {
    margin-bottom: 2.5rem;
  }
}
