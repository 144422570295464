@use "sass:math";

$browser-context: 16;
//Convert pixels to rem
@function toRem($value) {
  $remValue: math.div($value, $browser-context) + rem;
  @return $remValue;
}

//em and rem are almost identical but em is preferable because of Safari
//Convert pixels to em
@function em($pixels, $context: $browser-context) {
  @if (unitless($pixels)) {
    $pixels: $pixels * 1px;
  }

  @if (unitless($context)) {
    $context: $context * 1px;
  }

  @return math.div($pixels, $context) * 1em;
}

//SCREEN BREAKPOINTS (AS IN BOOTSTRAP)
$sm: 576;
$md: 768;
$lg: 992;
$xl: 1200;
$xxl: 1400;

$media: (
  sm: $sm,
  md: $md,
  lg: $lg,
  xl: $xl,
  xxl: $xxl,
);

@mixin respond($breakpoint) {
  @each $breakpoints, $size in $media {
    @if $breakpoint==$breakpoints {
      @media screen and (min-width: $size) {
        @content;
      }
    }
  }
}

@mixin landingPageSectionContainer {
  max-width: 1200px;
  margin: 0 auto;
  margin-top: 120px;
  scroll-margin-top: 110px;
}

@mixin landingPageSectionContainerSmall {
  margin-top: 80px;
  scroll-margin-top: 100px;
}

@mixin landingPageSectionTitle {
  text-align: center;
  color: #424242;
  font-size: 42px;
  font-weight: bold;
  font-family: Montserrat;
}

@mixin landingPageSectionSubTitle {
  color: #424242;
  font-weight: bold;
  font-size: 24px;
  font-family: Montserrat;
}

@mixin landingPageSectionText {
  color: #424242;
  font-size: 1rem;
  font-family: Montserrat;
}

@mixin primaryBtn {
  font-family: OpenSans;
  font-size: 18px;
  background-color: $color-primary;
  color: $color-StrypesWhite;
  padding: 12px 30px;
  border-radius: 12px;
  border: none;
  border: 1px solid $color-BlackLighter;
  text-transform: uppercase;
  text-align: center;

  &:hover {
    background-color: $color-Crusta;
  }
}

@mixin secondaryBtn {
  @include primaryBtn;
  background-color: $color-White;
  color: $color-primary;
  border-color: $color-primary;

  &:hover {
    background-color: $color-PaperHeartLight;
  }
}

@mixin formBtn {
  @include primaryBtn;
  font-size: 14px;
  border-radius: 6px;
  padding: 10px 15px;
  text-transform: uppercase;
}

@mixin secondaryFormBtn {
  @include formBtn;

  background-color: $color-White;
  border: 1px solid $color-primary;
  color: $color-primary;

  &:hover {
    background-color: $color-Crusta;
    border-color: $color-Crusta;
    color: $color-White;
  }
}

@mixin card {
  max-width: 100%;
  overflow: auto;
  box-shadow: 0px 9px 46px $color-BlackLighter;
  border-radius: 20px;
  border: 1px solid $color-BlackLighter;
  background-color: $color-StrypesWhite;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@mixin formLabel {
  width: 100%;
  text-align: start;
  font-family: OpenSans;
  font-size: 12px;
  color: $color-DoveGray;
}

@mixin formInput {
  width: 100%;
  background-color: $color-AliceBlue;
  border-radius: 6px;
  border: none;
  font-size: 14px;
  font-family: OpenSans;
  color: $color-EveningBlue;
  padding: 10px 15px;
  border: 1px solid $color-AliceBlue;

  &:focus {
    border-color: $color-UltramarineBlue;
  }
}

@mixin elevation {
  box-shadow: 0px 10px 20px $color-LavenderGrayLighter;
}

@mixin dashboardSection {
  max-width: 100%;
  background-color: $color-StrypesWhite;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  @include elevation;
}

@mixin applicationStatus {
  color: $color-White;
  text-transform: uppercase;
  border-radius: 0.25rem;
  font-size: 12px;
  padding: 0.25rem 0.75rem;
  z-index: 100;
}
