@import '../../../../../../../common/helper.scss';
@import '../../../../../../../common/variables.scss';

.user-import-container {
  background: $color-White 0% 0% no-repeat padding-box;
  box-shadow: em(0) em(10) em(25) $color-LavenderGrayLighter;
  border-radius: em(8);
  opacity: 1;
  width: em(669);
  height: em(250);
  padding-left: em(25);
}

.user-import-csv-btn {
  background: $color-primary;
  border: none;
  height: em(42);
  color: $color-Alabaster;
  text-transform: uppercase;
  font-size: em(14);
  min-width: em(150);
  border-radius: em(7);
  margin-left: em(20);
}

.user-import-headline {
  font-size: em(18);
  display: block;
  margin-top: em(20);
  margin-left: em(10);
  font-family: OpenSansRegular;
}

.user-import-step {
  display: inline-block;
  margin-bottom: em(20);
  margin-left: em(10);
  margin-top: em(21);
  font-family: OpenSansBold;
}

.user-import-step-content {
  font-family: OpenSansRegular;
  margin-left: em(5);
}

.download-template-link a {
  color: $color-Black;
  text-decoration: underline;
}
