.path-status-box {
  &-title {
    font-size: 14px;
  }

  &-text {
    font-size: 14px;
    font-weight: bold;
  }
}



