@import '../../../common/variables.scss';
@import '../../../common/helper.scss';

.contact-form-container {
  background-color: $color-StrypesWhite;
  max-width: 1200px;
  width: 100%;
  box-shadow: 0px 9px 46px $color-BlackLighter;
  padding: 1px;
  border-radius: 1rem;
  overflow: hidden;
  display: flex;
  flex-direction: row;

  &-small {
    @extend .contact-form-container;
    flex-direction: column;
  }

  .contact-form-image {
    width: 50%;
    flex: 1;
    object-fit: cover;
    object-position: top left;
    border-radius: 1rem 0 0 1rem;

    &-small {
      @extend .contact-form-image;
      width: 100%;
    }
  }

  .contact-form {
    flex: 50%;
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 40px;
    gap: 25px;

    &-small {
      @extend .contact-form;
      padding: 30px;
      gap: 15px;
    }

    .contact-form-title {
      @include landingPageSectionTitle;
      text-align: start;
    }

    .contact-form-field-container {
      flex: 1;
      max-width: 100%;
    }

    .contact-form-full-name {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 25px;
    }

    .contact-form-error-message {
      font-size: 0.875rem;
      height: 1.5rem;
      color: $color-error;
    }

    .contact-form-send-btn {
      align-self: flex-end;
    }
  }
}

.feedback-form-title {
  font-size: em(36);
  font-family: OpenSansBold;
}

.contact-form-field-label {
  font: normal normal normal 12px/16px Montserrat;
  color: $color-DoveGray;
}

.contact-form-required {
  font-size: em(12);
  color: $color-error;
  padding-right: em(3);
}

.contact-us-form-input-field:focus,
.contact-us-form-textarea-field:focus {
  border: em(1) solid $color-UltramarineBlue;
}

.contact-form-field-error {
  border: em(1) solid $color-error !important;
}

.contact-form-label-error {
  color: $color-WhiteMetal;
}

@media only screen and (max-width: 1024px) {
  .contact-form-image {
    flex: 40%;
  }

  .contact-us-form-container {
    flex: 60%;
    max-width: 100%;
  }
}

@media only screen and (max-width: 800px) {
  .contact-form-image,
  .contact-us-form-container {
    flex: 100%;
    max-width: 100%;
    aspect-ratio: 16/9;
  }

  .contact-us-form-container {
    padding: em(52) em(18) em(0) em(18);
  }
}
