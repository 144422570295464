@import '../../common/variables.scss';
@import '../../common/helper.scss';

.landing-header {
  width: 100%;
  background: $color-White;
  box-shadow: 0px 4px 8px #5f5f5f40;

  & * {
    font-family: Montserrat !important;
  }

  &-content {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    max-width: 1920px;
    width: 100%;
  }
}

.landing-navbar-toolbar {
  display: flex;
  width: 100%;
  padding: 1.3rem;
}

.landing-navbar-logo {
  &:hover {
    text-decoration: none;
  }
}

.landing-navbar-links {
  flex: 1;
  width: 100%;
}

.landing-navbar-links-link {
  color: $color-text;
  font-size: 18px;
  font-weight: 500;
  text-transform: capitalize;

  &:hover {
    @extend .landing-navbar-links-link;
    text-decoration: solid underline $color-primary 3px;
    text-underline-offset: 0.6rem;
  }

  &.active {
    @extend .landing-navbar-links-link;
    text-decoration: solid underline $color-primary 3px;
    text-underline-offset: 0.6rem;
    font-weight: 600;
  }
}

.landing-navbar-links-login {
  color: $color-primary;
  cursor: pointer;

  &:hover {
    color: $color-primary;
  }
}
