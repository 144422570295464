@import '../../common/variables.scss';
@import '../../common/helper.scss';


.assign-training {
  width: 500px;
  max-width: 100%;
  display: flex;
  flex-direction: column;

  &-header {
    padding: 1rem;
  }

  &-content {
    height: 300px;
    padding: 1rem;
  }

  &-footer {
    padding: 1rem;
    display: flex;
    gap: 0.5rem;
    justify-content: flex-end;
  }
}