@import '../../../common/variables.scss';
@import '../../../common/helper.scss';

.donut-chart {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 100%;
  column-gap: 30px;
  row-gap: 15px;

  .donut-chart-legend {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0;
    margin: 0;

    &-item {
      margin-bottom: 7.5px;
      list-style: none;
      display: flex;
      align-items: center;
      gap: 10px;

      &-marker {
        width: 10px;
        min-width: 10px;
        height: 10px;
        min-height: 10px;
        border-radius: 50%;
      }

      &-text {
        font-size: 13px;
        font-family: OpenSans;
        color: $color-text;
      }
    }
  }
}
