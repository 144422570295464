@import '../../../common/variables.scss';
@import '../../../common/helper.scss';

.event-details-overview-text-container {
  overflow: auto;
}

.event-details-overview-text {
  font-size: 14px;
  white-space: pre-wrap;
}

.img-no-overview {
  height: 64px;
  object-fit: contain;
}
