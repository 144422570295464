@import '../../variables.scss';

.modal-container {
  overflow-y: auto;
  flex-direction: column;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: safe center;
  justify-content: safe center;
  padding: 1rem;
  scroll-padding-top: 1rem;
  background-color: rgba(#0e2830, 0.5);

  &-right {
    @extend .modal-container;
    align-items: safe flex-end;
    padding: 0;
  }

  .modal-dialog {
    margin: 0;
    width: auto;
    max-width: 100%;
    pointer-events: auto;

    &-right {
      @extend .modal-dialog;
      height: 100%;
    }

    .modal-content {
      background-color: $color-White;
      box-shadow: 0px 11px 15px $color-BlackLighter;
      width: auto;
      max-width: 100%;
      border-radius: 1rem;
      overflow: hidden;

      &-right {
        @extend .modal-content;
        border-radius: 0;
        height: 100%;
        width: 700px;
        max-width: 100%;
      }
    }

    .modal-close-btn-outside {
      position: absolute;
      top: -1rem;
      right: -1rem;
      pointer-events: all;
      box-shadow: 0px 11px 15px $color-BlackLighter;
    }

    .modal-close-btn-inside {
      position: absolute;
      top: 0.5rem;
      right: 0.5rem;
      pointer-events: all;
    }
  }
}
