@import '../../common/variables.scss';
@import '../../common/helper.scss';

.contact-section-container {
  @include landingPageSectionContainer();
  display: flex;
  flex-direction: column;

  &-small {
    @extend .contact-section-container;
    @include landingPageSectionContainerSmall;
  }

  .contact-section-title {
    @include landingPageSectionTitle();
    margin-top: 70px;
  }

  .contact-section-descirption {
    @include landingPageSectionText();
    padding-top: 20px;
    white-space: pre-wrap;
    text-align: center;
  }

  .contact-section-btn-container {
    margin-top: 50px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    flex: 1;
    gap: 30px;

    .contact-section-request-btn {
      @include primaryBtn;
    }
  }
}
