@import '../../../../../../common/variables.scss';
@import '../../../../../../common/helper.scss';

.group-title {
  font-size: 20px;
  font-weight: bold;
  color: $color-ChathamsBlue;
  flex: 1;
}

.root {
  border: em(1) solid rgba(0, 0, 0, 0.125);
  border-left: em(6) solid #00aac2;
  box-shadow: em(1) em(1) grey;
  margin-bottom: em(10);
  margin-top: em(10);
  border-radius: 25%;
  height: 4%;
}

.header-border {
  border-bottom: em(1) solid #d0d0cf !important;
}
