@import '../../common/variables.scss';

.card-container {
  display: inline-flex;
  margin-bottom: 95px;
}

.card-shadow {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 10px 20px #00aac226;
  opacity: 1;
  border-radius: 6px;
}
