@import '../../common/variables.scss';

.main-footer {
  color: $color-StrypesWhite;
  background-color: $color-ChathamsBlue;
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 2rem;
  margin-top: 2rem;
  padding: 0.5rem 2rem;
  align-items: center;

  .footer-text {
    flex: 1;
    font-size: 14px;
  }
}

.footer-link {
  color: $color-StrypesWhite;
  text-decoration: none;
}

.footer-link:hover {
  color: $color-StrypesOrange;
}

.footer-light {
  font-family: OpenSansLight;
}