@import '../../../../common/helper.scss';
@import '../../../../common/variables.scss';

.cost-report-table .MuiTableCell-root {
  padding-right: em(0) !important;
  padding-left: em(0) !important;
  padding-top: em(10) !important;
  padding-bottom: em(10) !important;
  vertical-align: baseline !important;
}

.cost-request-header {
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
}

.cost-title {
  font-size: 18px;
  font-weight: bold;
}

.cost-text {
  font-size: 1rem;
}

.cost-separator-text {
  font-size: 16px;
  font-weight: bold;
}

.cost-report-column {
  width: 25%;
}
.cost-report-table-footer {
  background-color: $color-CulturedPearl;
}
