@import '../../../common/variables.scss';

.event-card-outer {
  position: relative;
}

.event-application-status {
  right: 0;
  top: 0rem;
  position: absolute;
  box-shadow: -2px 3px 6px #b8b8b8;
  @include applicationStatus;
}

.event-card {
  display: flex;
  flex-direction: column;
  border-radius: 1.25rem;
  overflow: hidden;
  box-shadow: 0px 10px 20px $color-LavenderGrayLighter;
  cursor: pointer;

  &-header {
    height: 120px;
    position: relative;

    .event-card-image {
      object-fit: cover;
      height: 100%;
      width: 100%;
    }

    .event-card-label {
      position: absolute;
      bottom: 0.5rem;
      left: 0.5rem;
      padding: 0.25rem 0.5rem;
      margin-right: 0.5rem;
      background-color: $color-primary;
      display: flex;
      flex-direction: row;
      gap: 0.5rem;
      align-items: center;

      .event-card-duration {
        color: $color-White;
        font-size: 12px;
        font-weight: bold;
      }
    }
  }

  &-content {
    padding: 1rem 1.5rem;
    height: 135px;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    .event-card-content-text {
      flex: 1;

      .event-card-title {
        color: $color-text;
        font-size: 14px;
        font-weight: bold;
        width: 100%;
        text-align: center;
      }

      .event-card-hours {
        color: $color-text;
        font-size: 12px;
        width: 100%;
        text-align: center;
      }
    }

    .event-card-price {
      align-self: center;
      color: $color-White;
      height: 1.5rem;
      padding: 0.25rem 0.5rem;
    }
  }
}
