@import '../../../common/variables.scss';
@import '../../../common/helper.scss';

.drop-area {
  border: 1px dashed $color-DarkSilver !important;
  border-radius: 1rem;
  min-height: 160px;
  max-width: 350px;
  padding: 2rem;
  width: 100%;
  background-color: $color-CulturedPearlLighter;
}
