@import '../../common/variables.scss';
@import '../../common/helper.scss';

.landing-page {
  max-width: 1920px;
  width: 100%;
  padding: 0 1.25rem;
  margin: 0 auto;

  & * {
    font-family: Montserrat !important;
  }
}
