@import '../../variables.scss';

.path-content-card {
  flex: 1;
  padding: 1rem;
  border: 1px solid $color-divider;
  border-radius: 0.5rem;
  cursor: pointer;
  background-color: $color-White;
  overflow: hidden;

  &-container {
    flex-direction: row;
  }

  &-position {
    width: 30px;
    align-items: center;
    position: relative;

    &-number {
      font-size: 14px;

      &-container {
        border-radius: 50%;
        border: 1px solid $color-border-second;
        width: 30px;
        height: 30px;
        position: absolute;
        align-items: center;
        justify-content: center;
        background-color: $color-White;
        margin: auto 0;
        top: 0;
        bottom: 0;
      }
    }
  }

  &-selected {
    border-color: $color-text;
  }

  &-icon {
    font-size: 36px !important;
    color: $color-icon-second;

    &-container {
      background-color: $color-Alabaster;
      padding: 1rem;
      align-items: center;
      justify-content: center;
      width: 68px;
      height: 68px;
    }
  }

  &-data {
    flex: 1;
    align-items: flex-start;
    min-width: 60%;

    & > a,
    & > a:hover {
      color: inherit;
    }

    &-title {
      font-weight: bold;
      -webkit-line-clamp: 1;
      font-size: 14px;
      max-width: 100%;
    }

    &-sub-title {
      font-size: 14px;
    }

    &-type {
      font-size: 14px;
    }

    &-rating {
      margin-top: 0.25rem;
      font-size: 18px;
      color: $color-warning;

      & .MuiRating-iconEmpty {
        color: $color-warning;
      }
    }

    &-title:hover {
      text-decoration: underline;
    }
  }

  &-actions {
    &-btn {
      margin: -0.75rem -0.75rem 0 0;

      &-icon {
        font-size: 1rem;
        color: $color-text;
      }
    }
  }
}
