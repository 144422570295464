@import '../../../../common/variables.scss';
@import '../../../../common/helper.scss';

.file-upload-cotainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.upload-file-icon {
  margin: 0 auto;
  width: em(40);
  display: block;
}

.file-upload-text {
  font-size: 14px;
  padding-top: em(35);
  display: inline-block;
  text-align: center;
}

.upload-file-error-container {
  padding-top: em(28);
  display: inline-block;
  text-align: center;
  color: $color-error;
  font-size: em(15);
}

.remove-file-symbol {
  font-size: 18px;
  font-weight: bold;
  display: inline;
  margin-left: 0.2rem;
  color: $color-text;
  cursor: pointer;
}

.uploaded-file {
  color: $color-ElectricViolet;
  display: inline-block;
  text-align: center;
}
