@import '../../../../../../../../../common/variables.scss';

.group-training-img-container {
  width: 10px;
  text-align: center;
  padding-left: 37px;
}

.group-training-display-title {
  text-transform: capitalize;
  font-family: OpenSansBold;
  font-weight: bold;
  height: 20px;
  max-width: 120px;
  color: $color-ChathamsBlue;
  font-size: 12px;
  padding-left: 30px;
}
.udemy-image {
  margin-bottom: 10px;
}

.cost-training {
  padding-left: 5px;
}

.group-training-container {
  border-radius: 10px;
  width: 150px;
  height: 170px;
  box-shadow: 0 3px 6px rgba($color-MineShaft, 0.5);
}

.remove-training {
  margin-right: em(4) !important;
}
