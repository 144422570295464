@import '../../../common/variables.scss';

.private-filter,
.private-filter:hover {
  background-color: $color-Mango;
}

.private-filter.skill-type-selected,
.private-filter.skill-type-selected:hover {
  background-color: $color-DarkerMango;
}
