@import '../../../common/variables.scss';
@import '../../../common/helper.scss';

.training-details-overview-text {
  font-family: OpenSansRegular;
  font-size: em(13);
  color: $color-ChathamsBlue;
}

.training-overview-text-container {
  max-height: em(350);
  overflow: auto;
  word-break: break-word;
  color: $color-ChathamsBlue;
  width: 100%;
}

.training-details-overview-title {
  font-family: OpenSansBold;
  font-size: em(16);
  text-transform: capitalize;
  color: $color-ChathamsBlue;
}

.training-details-overview {
  white-space: pre-wrap;
}