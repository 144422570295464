.employees-select {
  width: 550px;
  max-width: 100%;
  align-items: flex-start;

  &-title {
    font-size: 1rem;
    font-weight: bold;
  }

  &-divider {
    margin: 0 -2rem;
  }

  &-add-btn {
    align-self: flex-end;
  }
}

.employee-autocomplete-text-field {
  & .custom-text-field-input-container {
    border-radius: 2rem;
  }

  & input {
    padding: 0.5rem 1rem !important;
    font-size: 14px;
  }
  & .MuiAutocomplete-endAdornment {
    margin-right: 0.5rem;
  }
}

.employees-select-list {
  width: 100%;
  height: 350px;

  &-title {
    font-size: 1rem;
    font-weight: 600;
  }
}

