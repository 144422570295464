.events-select {
  height: 100%;
  padding: 1.5rem 0;

  &-title {
    font-weight: bold;
    font-size: 18px;
    padding: 0 2rem;
  }

  &-list {
    gap: 1rem;
    padding: 0 2rem 1.5rem 2rem;
    flex: 1;

    &-container {
      flex: 1;
      overflow: hidden;
    }

    &-divider {
      margin-top: auto;
    }
  }

  &-footer {
    flex-direction: row;
    justify-content: flex-end;
    padding: 0 2rem;
  }
}
