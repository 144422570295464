@import '../../variables.scss';

.custom-text-field {
  min-width: 150px;
  max-width: 100%;
}

.custom-number-field {
  min-width: 130px;
  width: 130px;
  max-width: 100%;
}

.custom-text-field-input-container {
  margin-top: 18px !important;
  background-color: $color-input;
  border: 1px solid $color-input;
  border-radius: 0.5rem;
  overflow: hidden;

  &-error {
    @extend .custom-text-field-input-container;
    border-color: $color-error;
  }

  & .MuiInputAdornment-positionStart {
    margin-left: 13px;
    margin-right: -4px;
    z-index: 1;
  }

  & .MuiInputAdornment-positionEnd {
    margin-left: -13px;
    margin-right: 4px;
    z-index: 1;
  }
}

.custom-text-field-input {
  font-size: 14px;
  padding: 0.5rem 13px;
}

.custom-text-field-helper-text {
  letter-spacing: 0;
}