@import '../../variables.scss';

.appliction-status-select {
  background-color: $color-input;
  border-radius: 0.5rem;
  padding: 0.625rem 1rem;
  font-weight: bold;
  color: $color-text;
  max-width: 100%;
  font-family: OpenSans;
  font-weight: normal;
  height: 37px;
  font-size: 12px;
}
