.paths-page {
  &-title {
    font-size: 18px;
    font-weight: bold;
  }

  &-create-btn {
    text-transform: uppercase;
  }

  &-create-fab {
    position: absolute;
    bottom: 4rem;
    right: 1.5rem;
  }
}

.paths-list {
  &-infinite-scroll {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    overflow: visible !important;
  }

  &-not-found {
    font-size: 14px;
    text-align: center;
    padding: 5rem 0;
  }
}