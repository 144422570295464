@import '../../../common/variables.scss';

.assign-container {
  min-height: 180px;
}

.assign-employee-search {
  position: absolute;
}

.groups-container {
  position: absolute;
}

.group-btn {
  min-width: 150px;
  border: 1px solid $color-MineShaft;
  border-radius: 50px;
  font-size: 12px;
  font-weight: OpenSansBold;
  color: $color-MineShaft;
  cursor: default !important;
}
