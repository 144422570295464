@import '../../../../../../../common/variables.scss';

.group-members-count {
  font-size: 14px;
  color: $color-ChathamsBlue;
  background-color: $color-CulturedPearl;
  border-radius: 0.5rem;
  border: 1px solid $color-Geyser;
  padding: 0.5rem 1.5rem;
}
