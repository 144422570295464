@import '../../../../common/variables.scss';

.speaker-avatar-2 {
  width: 52.8px;
  height: 52.8px;
}

.speaker-name-2 {
  font-size: 14px;
  font-weight: bold;
}

.speaker-job-title-2 {
  font-size: 14px; 
}

.speaker-company-2 {
  font-size: 14px; 
  font-weight: bold;
}
