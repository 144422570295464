.demo-request-action-modal {
  max-width: 100%;
  padding: 1.5rem 2rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: center;

  &-btns {
    width: 100%;
    display: flex;
    justify-content: space-around;
    gap: 1.5rem;
  
    &-btn {
      flex: 1;
    }
  }
}