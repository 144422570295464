@import '../../../../../../../../common/variables.scss';

.group-trainings-count {
  font-size: 14px;
  color: $color-ChathamsBlue;
  background-color: $color-CulturedPearl;
  border-radius: 0.5rem;
  border: 1px solid $color-Geyser;
  padding: 0.5rem 1.5rem;
}

.all-group-trianings-container {
  width: 100%;
  overflow-x: auto;
  padding-left: 10px;
  padding-bottom: 25px;
}

.all-group-trianings-container::-webkit-scrollbar {
  height: 12px;
}

.all-group-trianings-container::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: $color-WhiteMetalLighter;
}

.all-group-trianings-container::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: $color-WhiteMetal;
}
