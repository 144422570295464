@import '../../common/variables.scss';
@import '../../common/helper.scss';

.rate-training-form {
  display: flex;
  flex-direction: column;
  padding: 2rem 3rem 3rem 3rem;
  gap: 2rem;
  max-width: 100%;
  width: 500px;
  align-items: center;

  &-small {
    @extend .rate-training-form;
    padding: 1rem;
  }

  h5 {
    font-size: 1.1rem;
    align-self: flex-start;
    color: $color-Black-Pearl;
  }

  h6 {
    margin-top: 1rem;
    text-align: center;
    color: $color-text;
    font-family: 'OpenSansRegular';
  }

  hr {
    width: 100%;
    margin-top: -1.5rem;
  }

  textarea {
    width: 100%;
    padding: 0.6rem;
    border-radius: 0.25rem;
  }

  fieldset {
    margin-top: 3rem;
    align-self: flex-end;
  }

  .skip-rate-btn {
    margin-right: 0.5rem;
  }
}

.rate-training-success {
  padding: 5rem;
}
