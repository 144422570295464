@import '../../../common/variables.scss';
@import '../../../common/helper.scss';

.training-card-outer {
  position: relative;

  &-archived {
    opacity: 0.5;
  }
}

.training-card {
  max-width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 1.25rem;
  overflow: hidden;
  box-shadow: 0px 10px 20px $color-LavenderGrayLighter;
  cursor: pointer;

  &-selected {
    border: 2px solid $color-secondary;
    cursor: pointer;
  }

  &-image {
    height: 5.4rem;
    margin-top: 2rem;
    object-fit: contain;
    width: 7.6rem;
    max-width: 100%;
    align-self: center;
  }

  &-content {
    padding: 0.8rem 1rem 1rem;
    height: 145px;
    font-size: 14px;
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    .training-card-content-text {
      flex: 1;

      .training-card-title {
        font-size: 14px;
        font-weight: bold;
        color: $color-text;
        width: 100%;
      }

      .training-card-rating {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0.2rem;
      }
    }

    .training-card-price {
      align-self: center;
      color: $color-White;
      height: 1.5rem;
      padding: 0.25rem 0.5rem;
    }
  }
}

.training-application-status {
  right: 0;
  top: 0rem;
  position: absolute;
  box-shadow: -2px 3px 6px #b8b8b8;
  @include applicationStatus;
}
