@import '../../common//variables.scss';

.payment-form {
  width: 1000px;
  max-width: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: row;

  &-small {
    @extend .payment-form;
    flex-direction: column;
  }

  &-image {
    width: 50%;
    flex: 1;
    object-fit: cover;

    &-small {
      height: 200px;
      width: 100%;
      object-fit: cover;
    }
  }

  &-container {
    width: 50%;
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 1.5rem 3rem 1.5rem 3rem;
    gap: 1.25rem;

    &-small {
      @extend .payment-form-container;
      width: 100%;
      padding: 1.5rem 2rem 1.5rem 2rem;
    }

    &-logo {
      margin-bottom: 0.25rem;
    }

    &-header {
      display: flex;
      flex-direction: column;
      gap: 0.25rem;

      &-title {
        font-size: 1.5rem;
        color: $color-text;
      }

      &-sub-title {
        font-family: Montserrat;
        font-size: 1rem;
        color: $color-text;

        span {
          font-size: 1.5rem;
          color: $color-primary;
          font-weight: bold;
        }
      }
    }

    &-full-name {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 1.25rem;

      & > * {
        flex: 1;
      }
    }

    &-pre-submit-info {
      font-size: 12px;
      color: $color-text;
      padding-top: 10px;
      padding-bottom: 0.5rem;
    }

    &-submit-btn {
      margin-bottom: 0.5rem;
    }
  }
}
