@import '../../variables.scss';

.uploaded-img {
  width: 52.8px;
  height: 52.8px;
  border-radius: 50%;
  object-fit: cover;
}

.image-dropzone-remove-btn-icon {
  color: $color-text;
  font-size: 20px;
}
