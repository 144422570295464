@import '../../variables.scss';

.path-card {
  padding: 1rem;
  background-color: $color-White;
  border-radius: 0.75rem;
  cursor: pointer;
  @include elevation;

  &-title {
    font-size: 18px;
    font-weight: bold;
    flex: 1;
  }

  &-creator-avatar {
    width: 42px;
    height: 42px;
    font-size: 16px;
  }

  &-creator-title, &-items-title, &-time-frame-title {
    font-size: 14px;
  }

  &-creator-text, &-items-text, &-time-frame-text {
    font-size: 14px;
    font-weight: bold;
  }
}
