@import '/src/common/variables.scss';

.filter-select {
  & .custom-text-field-input-container {
    height: 37px !important;
    height: 100%;
  }

  & .custom-text-field-input {
    padding-left: 1rem;
    padding-right: 2rem !important;
    padding-top: 0;
    padding-bottom: 0;
    font-size: 14px;
    height: 100%;
    display: flex;
    align-items: center;
  }
  & .MuiSelect-icon {
    margin-right: 0.5rem;
  }
}
