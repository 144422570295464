@import '../../../common/variables.scss';
.event-details-header {
  &-image {
    width: 180px;
    max-width: 100%;
    height: 50px;
    object-fit: contain;
  }

  &-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    max-width: 100%;

    .event-details-title {
      font-size: 1.25rem;
      text-transform: capitalize;
      color: $color-text;
    }

    .event-details-location {
      color: $color-secondary;
      font-family: OpenSans;
      font-size: 14px;
    }

    .event-details-creator {
      font-family: OpenSans;
      font-size: 14px;
    }
  }

  .event-details-actions {
    margin-left: auto;
  }
}
