@import '../../../common/variables.scss';
@import '../../../common/helper.scss';

.my-trainings-section {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 20px 40px;
  align-items: center;
  justify-content: center;
  gap: 20px;

  .my-trainings-section-data {
    min-width: 50%;
    flex: 1;
  }
  
  .my-trainings-section-chart-data {
    width: 350px;
    max-width: 100%;
  }
}
