@import '../../common/variables.scss';
@import '../../common/helper.scss';

.profile-photo-upload {
  width: 500px;
  max-width: 100%;
  max-height: 100%;
}
.edit-profile-photo-container {
  margin: 0 auto;
  padding: 1.5rem;
}

.upload-profile-photo-actions {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-end;
  gap: 0.5rem;
  padding: 1rem;
}
