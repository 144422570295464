@import '../../common/variables.scss';
@import '../../common/helper.scss';

.training-details {
  width: 1200px;
  max-width: 100%;
  min-height: 720px;
  max-height: 100%;
}

.training-details-btn-link {
  text-decoration: underline;

  &:hover {
    text-decoration: underline;
  }
}

.training-details-file {
  font-size: 14px;
}

.training-details-sections {
  flex: 1;
  max-width: 100%;
}

.training-details-overview-speakers {
  max-width: 100%;
}
