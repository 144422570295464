.agenda-details {
  max-width: 100%;

  &-title {
    font-size: 1rem;
    font-weight: bold;
  }
}

.event-details-agenda-items {
  width: 100%;
  max-height: 300px;
  overflow-y: auto;
}

.img-no-agenda {
  height: 64px;
  object-fit: contain;
}