@import '../../common/helper.scss';

.solution-section-container {
  @include landingPageSectionContainer();

  &-small {
    @extend .solution-section-container;
    @include landingPageSectionContainerSmall;
  }
}

.solution-section-title {
  @include landingPageSectionTitle();
}

.solution-section-items-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 60px;
  gap: 40px;

  &-small {
    @extend .solution-section-items-container;
    gap: 20px;
    flex-direction: column;
  }
}

.solution-section-roles-section {
  margin-top: 60px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 40px;
  flex: 1;
  justify-content: center;

  &-small {
    @extend .solution-section-roles-section;
    gap: 20px;
    flex-direction: column;
  }

  .solution-section-roles-section-image {
    width: 45%;
    object-fit: cover;

    &-small {
      @extend .solution-section-roles-section-image;
      width: 100%;
    }
  }

  .solution-section-roles-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 100%;

    .solution-section-roles-container-title {
      margin-top: em(10);
    }

    .solution-section-roles-items-container {
      flex: 1;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: em(40);
      align-items: center;
      justify-content: center;
    }
  }
}