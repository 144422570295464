@import '../../../../../../../common/variables.scss';

.trainings-select-card {
  flex: 1;
  padding: 1rem;
  border: 1px solid $color-divider;
  border-radius: 0.5rem;
  cursor: pointer;
  background-color: $color-White;

  &-selected {
    border-color: $color-text;
  }

  &-icon {
    font-size: 36px !important;
    color: $color-icon-second;

    &-container {
      background-color: $color-Alabaster;
      padding: 1rem;
      align-items: center;
      justify-content: center;
      width: 68px;
      height: 68px;
    }
  }

  &-data {
    flex: 1;
    align-items: flex-start;
    min-width: 60%;

    & > a,
    & > a:hover {
      color: inherit;
    }

    &-title {
      font-weight: bold;
      -webkit-line-clamp: 1;
      font-size: 14px;
      max-width: 100%;
    }

    &-sub-title {
      font-size: 14px;
    }

    &-rating {
      margin-top: 0.25rem;
      font-size: 18px;
      color: $color-warning;

      & .MuiRating-iconEmpty {
        color: $color-warning;
      }
    }

    &-title:hover {
      text-decoration: underline;
    }
  }
}
