@import '../../common/helper.scss';
@import '../../common/variables.scss';
.group-select {
  width: em(655);
}

.css-twkkyj-control {
  border-color: $color-Geyser !important;
}

.css-cf9let-indicatorContainer {
  color: $color-Geyser !important;
}

.css-1jgbanz-indicatorSeparator {
  display: none;
}

.css-1o70pz1-multiValue {
  background-color: $color-Seachange !important;
  color: $color-White !important;
}

.css-xrpreq:hover {
  background-color: $color-Seachange !important;
  color: $color-White !important;
}

.css-1d67vx3 {
  color: $color-White !important;
}
