@import '../../../common/variables.scss';

.events-section {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0 40px;
  color: $color-text;
  height: 100%;
  column-gap: 20px;

  &-no-data {
    min-width: 60%;
    display: flex;
    flex: 1;
    align-items: center;
    padding: 20px 0;
    font-size: 14px;

    &-text {
      text-align: center;
      width: 100%;
    }

    &-link {
      color: $color-secondary;
    }
  }

  &-data {
    flex: 1;
    min-width: 60%;
    display: flex;
    padding: 20px 0;

    &-list-small {
      grid-template-columns: 1fr !important;
    }
  }

  &-calendar {
    padding-top: 10px;

    &-small {
      padding-bottom: 20px;
    }
  }
}