@import '../../../common/variables.scss';
@import '../../../common/helper.scss';

.event-form-btn-link {
  text-decoration: underline;

  &:hover {
    text-decoration: underline;
  }
}

.event-attached-files {
  color: $color-DoveGray;
  font-family: OpenSansLight;
}

.rdw-fontfamily-placeholder {
  color: $color-Black;
}

.rdw-dropdown-selectedtext {
  color: $color-Black;
}

.rdw-dropdown-selectedtext:hover {
  color: $color-Black;
}
